import { graphql, useStaticQuery } from 'gatsby'

export const useCxHardware = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulMxContainer(
        filter: { contentful_id: { eq: "7567m0ieCqB1YSAZfdaEEe" } }
      ) {
        data: edges {
          node {
            sectionModules {
              moduleTitle
              moduleMisc {
                description
              }
              images {
                title
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              contentSlots {
                markdown {
                  raw
                }
                photos {
                  title
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                slotTitle
              }
            }
          }
        }
      }
    }
  `)
  return content
}
